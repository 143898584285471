import { cn } from '@/lib/cn';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { useEffect, useState } from 'react';

import { Button } from '../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import {
  BOOLEAN_FILTER,
  NULL_FILTER,
  RELATION_FILTER,
  SELECT_FILTER,
  TAG_FILTER,
  getReadableOperator,
  type AppliedFilter,
  type Filter,
  type FilterValue,
  type FilterOperator,
} from './constants';
import FilterValueInput from './filter-value-input';
import RelationDisplay from './relation-display';
import RelationModal from './relation-modal';

export default function FilterItem({
  filterConfig,
  filter,
  updateFilterOperator,
  updateFilterValue,
  removeFilter,
  TableFilters,
}: {
  filterConfig: Filter;
  filter: AppliedFilter;
  updateFilterOperator: (operator: FilterOperator) => void;
  updateFilterValue: (value: FilterValue) => void;
  removeFilter: (operator: FilterOperator) => void;
  TableFilters: ({
    clientState,
    filters,
    tableId,
  }: {
    clientState?: boolean;
    filters: Filter[];
    tableId: string;
  }) => JSX.Element;
}) {
  const [editValueOpen, setEditValueOpen] = useState(false);
  const [editOperatorOpen, setEditOperatorOpen] = useState(false);
  const [relationSelectOpen, setRelationSelectOpen] = useState(false);
  const [value, setValue] = useState<FilterValue>(null);

  const onOperatorChanged = (operator: FilterOperator) => {
    updateFilterOperator(operator);
    setEditOperatorOpen(false);
  };

  const onValueChanged = (val: FilterValue) => {
    updateFilterValue(val);
    setEditValueOpen(false);
    setRelationSelectOpen(false);
  };

  useEffect(() => {
    setValue(filter.value);

    if (filter.value === '') {
      setEditValueOpen(true);
    }
  }, [filter.value]);

  return (
    <>
      {relationSelectOpen &&
        (filterConfig.kind === RELATION_FILTER ||
          filterConfig.kind === TAG_FILTER) && (
          <RelationModal
            TableFilters={TableFilters}
            filter={filterConfig}
            initialValue={(value as string[]) ?? []}
            onDone={(val) => {
              onValueChanged(val);
              setRelationSelectOpen(false);
            }}
          />
        )}

      <div className='flex flex-nowrap items-center'>
        <Button
          className='rounded-l-full  border-r-background py-1.5 pl-3 pr-1 text-xs font-medium'
          variant='outline'
        >
          {filterConfig.title}
        </Button>

        <Popover
          open={editOperatorOpen}
          onOpenChange={(open) => {
            setEditOperatorOpen(open);
          }}
        >
          <PopoverTrigger asChild>
            <Button
              className=' rounded-none border-x-background px-1 py-1.5 text-xs'
              variant='outline'
            >
              {getReadableOperator(filter.operator, filterConfig.kind)}
              <ChevronDownIcon
                className={cn(
                  'size-4 transition-transform duration-200',
                  editOperatorOpen ? 'rotate-180' : 'rotate-0'
                )}
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent className='grid p-0'>
            {filterConfig.operators.map((operator) => (
              <Button
                variant='ghost'
                className='justify-start text-start'
                key={operator}
                onClick={() => {
                  onOperatorChanged(operator);
                }}
              >
                {getReadableOperator(operator, filterConfig.kind)}
              </Button>
            ))}
          </PopoverContent>
        </Popover>
        {filterConfig.kind === NULL_FILTER ||
        filter.operator === '__isnull' ||
        filter.operator === '__isnull!' ? null : filterConfig.kind ===
            RELATION_FILTER || filterConfig.kind === TAG_FILTER ? (
          <>
            <Button
              onClick={() => setRelationSelectOpen(true)}
              variant='outline'
              className='rounded-none border-x-background py-1.5 pl-1 pr-3 text-xs font-medium'
            >
              <RelationDisplay filterConfig={filterConfig} filter={filter} />
            </Button>
          </>
        ) : (
          <Popover
            open={editValueOpen}
            onOpenChange={(open) => {
              if (!open && value != null && value != filter.value) {
                onValueChanged(value);
              }
              setEditValueOpen(open);
            }}
          >
            <PopoverTrigger asChild>
              <Button
                variant='outline'
                className='rounded-none border-x-background p-1.5 text-xs font-medium'
              >
                {filterConfig.kind === SELECT_FILTER &&
                (Array.isArray(filter.value) || filter.value == null)
                  ? filter.value && filter.value?.length > 0
                    ? (filter.value || [])
                        .map(
                          (f) => filterConfig.choices!.find((c) => c.v == f)?.d
                        )
                        .join(', ')
                    : '...'
                  : filterConfig.kind === BOOLEAN_FILTER
                    ? filter.value === false
                      ? 'Falskt'
                      : 'Sant'
                    : (filter.value ?? '...')}
              </Button>
            </PopoverTrigger>
            <PopoverContent className='p-0'>
              <FilterValueInput
                onDone={() => onValueChanged(value)}
                filter={filterConfig}
                value={value}
                setValue={(val) => setValue(val)}
              />
            </PopoverContent>
          </Popover>
        )}
        <Button
          onClick={() => removeFilter(filter.operator)}
          variant='outline'
          className='rounded-l-none rounded-r-full border-l-background py-1.5 pl-1 pr-2 text-xs font-medium'
        >
          <XMarkIcon className='size-4' />
        </Button>
      </div>
    </>
  );
}
