'use client';

import { Input } from '@/components/ui/input';
import { useTableFilter } from '@/hooks/use-table-filter';
import { useDebounceCallback } from 'usehooks-ts';

export default function TableSearch({
  title,
  isClient,
  tableId,
  count,
}: {
  title?: string;
  isClient?: boolean;
  tableId: string;
  count?: number;
}) {
  const { search, setSearch } = useTableFilter({ isClient, tableId });
  const debounced = useDebounceCallback(setSearch, 500);

  return (
    <Input
      className='w-80'
      placeholder={
        count
          ? `Sök bland ${count} ${title?.toLowerCase() ?? 'objekt'}...`
          : 'Sök...'
      }
      defaultValue={search ?? ''}
      onChange={(event) => {
        debounced(event.target.value);
      }}
    />
  );
}
