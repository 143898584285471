import { useState } from 'react';
import { Button } from '../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import {
  BOOLEAN_FILTER,
  RELATION_FILTER,
  SELECT_FILTER,
  TAG_FILTER,
  getReadableOperator,
  type Filter,
  type FilterValue,
} from './constants';
import FilterValueInput from './filter-value-input';
import RelationModal from './relation-modal';

export default function CurrentCreatingFilter({
  currentCreating,
  onDone,
  TableFilters,
}: {
  currentCreating: Filter;
  onDone: (value: FilterValue) => void;
  TableFilters: ({
    clientState,
    filters,
    tableId,
  }: {
    clientState?: boolean;
    filters: Filter[];
    tableId: string;
  }) => JSX.Element;
}) {
  const [currentCreatingValue, setCurrentCreatingValue] =
    useState<FilterValue>(null);

  return (
    <>
      {(currentCreating.kind === RELATION_FILTER ||
        currentCreating.kind === TAG_FILTER) && (
        <RelationModal
          TableFilters={TableFilters}
          filter={currentCreating}
          initialValue={(currentCreatingValue as unknown as string[]) ?? []}
          onDone={(val) => onDone(val)}
        />
      )}

      <div className='flex flex-nowrap items-center'>
        <Button
          className='rounded-l-full  border-r-background py-1.5 pl-3 pr-1 text-xs font-medium'
          variant='outline'
        >
          {currentCreating.title}
        </Button>
        <Button
          className=' rounded-none border-x-background px-1 py-1.5 text-xs'
          variant='outline'
        >
          {getReadableOperator(
            currentCreating.defaultOperator,
            currentCreating.kind
          )}
        </Button>

        {currentCreating.kind === RELATION_FILTER ||
        currentCreating.kind === TAG_FILTER ? null : (
          <Popover
            modal
            open={true}
            onOpenChange={(open) => {
              if (open) return;
              onDone(currentCreatingValue);
            }}
          >
            <PopoverTrigger asChild>
              <Button
                variant='outline'
                className='rounded-l-none rounded-r-full border-l-background py-1.5 pl-1 pr-3 text-xs font-medium'
              >
                {currentCreating.kind === SELECT_FILTER &&
                (Array.isArray(currentCreatingValue) ||
                  currentCreatingValue == null)
                  ? currentCreatingValue && currentCreatingValue?.length > 0
                    ? (currentCreatingValue || [])
                        .map(
                          (f) =>
                            currentCreating.choices!.find((c) => c.v === f)?.d
                        )
                        .join(', ')
                    : '...'
                  : currentCreating.kind === BOOLEAN_FILTER
                    ? currentCreatingValue === false
                      ? 'Falskt'
                      : 'Sant'
                    : (currentCreatingValue ?? '...')}
              </Button>
            </PopoverTrigger>
            <PopoverContent className='p-0'>
              <FilterValueInput
                onDone={() => onDone(currentCreatingValue)}
                filter={currentCreating}
                value={currentCreatingValue}
                setValue={(val) => setCurrentCreatingValue(val)}
              />
            </PopoverContent>
          </Popover>
        )}
      </div>
    </>
  );
}
