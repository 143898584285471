'use client';

import { useConfig } from '@/hooks/useConfig';
import { raise } from '@/lib/utils';
import { useGetList } from '@/requests/hooks';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { isArray } from 'lodash';
import { TAG_FILTER, type AppliedFilter, type Filter } from './constants';

export default function RelationDisplay<T extends BaseInstance>({
  filterConfig,
  filter,
}: {
  filterConfig: Filter;
  filter: AppliedFilter;
}) {
  if (!filterConfig.relationModelName)
    raise('Modelname missing from filter config');

  if (!isArray(filter.value))
    raise(`Can't pass ${typeof filter.value} to relation display.`);

  const { data: values } = useGetList<T>({
    modelName: filterConfig.relationModelName,
    queryParams: {
      filters: {
        id: { __in: filter.value.join(',') },
      },
    },
    enabled: Array.isArray(filter.value),
  });

  const { config } = useConfig<T>(filterConfig.relationModelName);

  if (!config || !values?.list) return 'Laddar...';

  const displayValue =
    values.list[0]?.[config.displayFieldName as keyof T] ?? values.list[0]?.id;

  const colorCode =
    values.list[0] &&
    'colorCode' in values.list[0] &&
    typeof values.list[0].colorCode === 'string'
      ? values.list[0].colorCode
      : undefined;

  return (
    <div className='flex items-center'>
      {filterConfig.kind === TAG_FILTER &&
        !!values?.list?.[0] &&
        'colorCode' in values.list[0] && (
          <div className='flex items-center space-x-2'>
            {' '}
            <div
              className='rounded-full p-1.5'
              style={
                colorCode
                  ? {
                      backgroundColor: colorCode?.startsWith('#')
                        ? colorCode
                        : `#${colorCode}`,
                    }
                  : undefined
              }
            />{' '}
            <div>{String(displayValue ?? 'Okänt objekt')} </div>
          </div>
        )}
      {filterConfig.kind !== TAG_FILTER && (
        <>
          {String(displayValue ?? 'Okänt objekt')}{' '}
          {values.list.length > 1 ? `+${values.list.length - 1}` : ''}
        </>
      )}
    </div>
  );
}
