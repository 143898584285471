import { getCustomDisplayName } from '@/components/form/dynamic-field/utils';
import { Icons } from '@/components/icons';
import type { Filter } from '@/components/table-filters/constants';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { useTableFilter } from '@/hooks/use-table-filter';
import { useConfig } from '@/hooks/useConfig';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import { cn } from '@/lib/cn';
import type { QueryParams } from '@/requests/api/types';
import { useGetInfiniteList } from '@/requests/hooks';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import { type ReactNode } from 'react';
import TableSearch from '../../filtering/search';
import { getSelectItemFromModelName } from './select-items/relation-item-map';

export type RenderItemProps<T extends BaseInstance> = {
  item: T;
  onSelected: (id: string) => void;
  isSelected: boolean;
  isMultiSelect: boolean;
};

export default function SelectTable<T extends BaseInstance = BaseInstance>({
  queryParams,
  filters,
  modelName,
  className,
  filterBoxClassName,
  isMultiSelect = false,
  onSelected,
  onSelectedInstance,
  selected,
  RenderItem,
  emptyTitle,
  tableId,
  isRelationField,
  nested,
  overrideUrl,
  TableFilters,
}: {
  modelName: ModelName;
  queryParams?: QueryParams;
  tableId: string;
  RenderItem?: ({
    item,
    onSelected,
    isSelected,
    isMultiSelect,
  }: RenderItemProps<T>) => ReactNode;
  className?: string;
  filters?: Filter[];
  filterBoxClassName?: string;
  isMultiSelect?: boolean;
  onSelected: (id: string) => void;
  onSelectedInstance?: (instance: T) => void;
  selected?: string[];
  emptyTitle?: string;
  isRelationField?: boolean;
  nested?: Array<keyof T>;
  overrideUrl?: string;
  TableFilters: ({
    clientState,
    filters,
    tableId,
  }: {
    clientState?: boolean;
    filters: Filter[];
    tableId: string;
  }) => JSX.Element;
}) {
  const { search, combineFilters } = useTableFilter({
    isClient: true,
    tableId,
  });
  const { config } = useConfig(modelName);
  const { data, isPending, hasNextPage, fetchNextPage, error, isFetching } =
    useGetInfiniteList<T>({
      overrideUrl,
      modelName,
      nested: nested ?? getSelectItemFromModelName(modelName)?.nested ?? [],
      queryParams: {
        ...queryParams,
        search,
        filters: {
          ...combineFilters(queryParams?.filters ?? {}),
        },
        page: 1,
        pageSize: 25,
      },
    });

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage,
    onLoadMore: fetchNextPage,
    disabled: !!error,
    rootMargin: '0px 0px 100px 0px',
  });

  // Put already selected items on top
  const flatData = data?.pages.flatMap((page) => page.list) ?? [];

  const renderDisplayName = (instance: T | undefined) => {
    if (!instance) return '';

    const customDisplay = getCustomDisplayName(config?.modelName, instance);

    if (customDisplay) return customDisplay;

    const displayKey = config?.displayFieldName;

    if (displayKey && instance[displayKey as keyof T] != null) {
      return instance[displayKey as keyof T]?.toString();
    }

    if (instance['customId'] != null) return instance['customId'];

    return instance['id'];
  };

  return (
    <div className={cn('flex h-[500px] flex-col', className)}>
      <div className={cn('flex flex-col gap-2 p-4', filterBoxClassName)}>
        <div className='flex flex-wrap items-center gap-2'>
          <TableSearch isClient={true} tableId={tableId} />
          <TableFilters
            filters={
              filters ?? getSelectItemFromModelName(modelName)?.filters ?? []
            }
            tableId={tableId}
            clientState={true}
          />
        </div>
      </div>

      <div className='h-full overflow-y-auto border-t'>
        {isPending &&
          Array.from(Array(10).keys()).map((i) => (
            <div key={i} className='flex grow flex-col gap-2'>
              <div className='flex w-full flex-col gap-2 p-4'>
                <div className='flex w-full items-center justify-between'>
                  <div className='flex items-center gap-1'>
                    <Skeleton className='h-4 w-[100px]' />
                    <Skeleton className='h-4 w-20' />
                  </div>
                  <Skeleton className='h-6 w-20' />
                </div>
                <div className='flex items-center gap-1'>
                  <Skeleton className='h-2 w-20' />
                  <Skeleton className='size-2 rounded-full' />
                  <Skeleton className='h-2 w-20' />
                  <Skeleton className='size-2 rounded-full' />
                  <Skeleton className='h-2 w-20' />
                </div>
              </div>
            </div>
          ))}

        {!isPending && flatData?.length === 0 && (
          <div className='flex h-full flex-col items-center justify-center gap-2 bg-background/80'>
            <MagnifyingGlassIcon className='size-12 opacity-50' />
            <h2 className='text-foreground'>Inga resultat</h2>
            <p>
              Vi kunde inte hitta några {emptyTitle?.toLowerCase() ?? 'objekt'}.
            </p>
          </div>
        )}

        {!!flatData.length &&
          flatData.map((item) => {
            const isSelected = !!selected?.includes(item.id);
            if (RenderItem) {
              return (
                <RenderItem
                  key={item.id}
                  item={item}
                  onSelected={onSelected}
                  isSelected={isSelected}
                  isMultiSelect={isMultiSelect}
                />
              );
            }
            return (
              <div
                key={item?.id}
                role='button'
                tabIndex={-1}
                onClick={() => {
                  onSelected(item.id);
                  if (onSelectedInstance) {
                    onSelectedInstance(item);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSelected(item.id);
                  }
                }}
                className='flex w-full flex-1 cursor-pointer items-center justify-between border-b p-4 transition-colors last:border-b-0 hover:bg-accent/50'
              >
                <div className='flex w-full flex-wrap items-center gap-0.5'>
                  <div
                    className={cn(
                      'mr-4',
                      !getSelectItemFromModelName(modelName).renderItem(item)
                        .avatar && 'hidden'
                    )}
                  >
                    {
                      getSelectItemFromModelName(modelName).renderItem(item)
                        .avatar
                    }
                  </div>
                  <div>
                    <div className='mb-1 flex items-center gap-2 font-medium'>
                      {getSelectItemFromModelName(modelName)?.renderItem(item)
                        .title ?? renderDisplayName(item)}
                      {
                        getSelectItemFromModelName(modelName)?.renderItem(item)
                          .badge
                      }
                    </div>
                    <div
                      className={cn(
                        'flex w-full flex-wrap items-center gap-0.5 whitespace-nowrap text-xs',
                        isRelationField ? 'ml-6' : ''
                      )}
                    >
                      {
                        getSelectItemFromModelName(modelName)?.renderItem(
                          item,
                          isRelationField,
                          modelName
                        ).descriptionItems
                      }
                    </div>
                  </div>
                </div>

                <Button
                  type='button'
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelected(item.id);
                    if (onSelectedInstance) {
                      onSelectedInstance(item);
                    }
                  }}
                  className='shrink-0'
                  variant={isSelected ? 'default' : 'outline'}
                >
                  {isMultiSelect
                    ? isSelected
                      ? 'Tillagd'
                      : 'Lägg till'
                    : isSelected
                      ? 'Vald'
                      : 'Välj'}
                </Button>
              </div>
            );
          })}
        {(isFetching || hasNextPage) && (
          <div
            ref={sentryRef}
            className='flex w-full items-center justify-center'
          >
            <Icons.loader className='my-4 size-4 animate-spin' />
          </div>
        )}
      </div>
    </div>
  );
}
