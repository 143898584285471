'use client';

import { useShortcut } from '@/hooks/use-shortcut';
import { useTableFilter } from '@/hooks/use-table-filter';
import { cn } from '@/lib/cn';
import { ObjectKeys } from '@/lib/utils';
import { PlusCircleIcon, PlusIcon } from '@heroicons/react/16/solid';
import { getCookie } from 'cookies-next';
import { useMemo, useState } from 'react';
import { Button } from '../ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import {
  ENUM_FILTER,
  FILTER_KINDS,
  NULL_FILTER,
  RELATION_FILTER,
  type AppliedFilter,
  type Filter,
  type FilterValue,
} from './constants';
import CurrentCreatingFilter from './current-creating';
import FilterItem from './filter-item';

export default function TableFilters({
  clientState,
  filters,
  tableId,
}: {
  clientState?: boolean;
  filters: Filter[];
  tableId: string;
}) {
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [currentCreating, setCurrentCreating] = useState<Filter | null>(null);
  const {
    filter,
    deleteAppliedFilterValue,
    setTableFilter,
    setFilterValue,
    updateAppliedFilterValue,
    updateAppliedFilterOperator,
    lastUsedFilter,
    getAllAppliedFilters,
  } = useTableFilter({
    isClient: clientState,
    tableId,
  });

  const organizationId = getCookie('organization_id');
  const globalFilter = getCookie('globalFilter');
  const parsedGlobalFilter = JSON.parse(globalFilter ?? '{}') as Record<
    string,
    string[]
  >;
  const hasAnyGlobalFilter = ObjectKeys(parsedGlobalFilter ?? {}).some(
    (gf) => parsedGlobalFilter[gf] && parsedGlobalFilter[gf].length > 0
  );
  useShortcut({
    isActive: !selectorOpen && !currentCreating,
    key: 'q',
    callback: () => {
      setTimeout(() => {
        // timeout to prevent command input to receiving Q text
        setSelectorOpen(true);
      }, 0);
    },
  });

  const memoizedCurrentCreating = useMemo(() => {
    return currentCreating;
  }, [currentCreating]);

  const createFilter = (val: FilterValue, explicitFilter?: Filter) => {
    const filterToCreate = currentCreating || explicitFilter;
    if (!filterToCreate) return;
    setFilterValue(filterToCreate, val);

    setCurrentCreating(null);
  };

  const getFilterConfig = (filter: AppliedFilter) => {
    return filters.find(
      (f) =>
        f.externalFieldName === filter.externalFieldName &&
        f.operators.includes(filter.operator)
    );
  };

  const appliedFilters = getAllAppliedFilters().filter(
    (appliedFilter) =>
      getFilterConfig(appliedFilter)?.kind !== FILTER_KINDS.ENUM_FILTER
  );

  return (
    <>
      {appliedFilters?.length > 0 &&
        appliedFilters.map((appliedFilter) => {
          const filterConfig = filters.find(
            (f) =>
              f.externalFieldName === appliedFilter.externalFieldName &&
              f.operators.includes(appliedFilter.operator)
          );

          if (!filterConfig) return null;
          if (appliedFilter.value === undefined) return null;

          return (
            <FilterItem
              TableFilters={TableFilters}
              key={`${appliedFilter.externalFieldName}${appliedFilter.operator}`}
              filterConfig={filterConfig}
              filter={appliedFilter}
              updateFilterOperator={(operator) =>
                updateAppliedFilterOperator(appliedFilter, operator)
              }
              updateFilterValue={(newVal) =>
                updateAppliedFilterValue(appliedFilter, newVal)
              }
              removeFilter={() => {
                deleteAppliedFilterValue(appliedFilter);
              }}
            />
          );
        })}

      {memoizedCurrentCreating && (
        <CurrentCreatingFilter
          TableFilters={TableFilters}
          currentCreating={memoizedCurrentCreating}
          onDone={(val) => {
            if (
              (val !== false && !val) ||
              (memoizedCurrentCreating.kind === RELATION_FILTER &&
                Array.isArray(val) &&
                val.length === 0)
            ) {
              setCurrentCreating(null);
            } else {
              createFilter(val);
            }
          }}
        />
      )}
      <Tooltip>
        <Popover
          open={selectorOpen}
          onOpenChange={(open) => setSelectorOpen(open)}
          modal
        >
          <PopoverTrigger asChild>
            <TooltipTrigger asChild>
              <Button
                variant='outline'
                className={cn('rounded-full text-xs ', {
                  'border-0 p-0':
                    !!currentCreating || appliedFilters.length > 0,
                })}
                size={
                  currentCreating || appliedFilters.length > 0
                    ? 'icon-sm'
                    : 'default'
                }
              >
                {currentCreating || appliedFilters.length > 0
                  ? ''
                  : 'Lägg till filter'}

                <PlusCircleIcon
                  className={cn('size-6', {
                    'ml-2 size-4':
                      !currentCreating && appliedFilters.length === 0,
                  })}
                />
              </Button>
            </TooltipTrigger>
          </PopoverTrigger>
          <PopoverContent className='p-0'>
            <Command>
              <CommandInput placeholder='Sök bland filter...' />
              <CommandList>
                <CommandEmpty>Hittade inga filter.</CommandEmpty>
                <CommandGroup>
                  {filters
                    .filter((f) => f.kind !== ENUM_FILTER)
                    .map((filter) => (
                      <CommandItem
                        onSelect={() => {
                          setSelectorOpen(false);

                          if (filter.kind === NULL_FILTER) {
                            createFilter(true, filter);
                          } else {
                            setCurrentCreating(filter);
                          }
                        }}
                        key={filter.externalFieldName}
                        value={filter.title}
                      >
                        {filter.title}
                      </CommandItem>
                    ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>

        <TooltipContent className='max-w-[150px]'>
          <div className='text-sm font-medium'>Tips!</div>
          <div className='text-xs'>
            Tryck på &quot;Q&quot; på tangentbordet för att snabbt lägga till
            filter.
          </div>
        </TooltipContent>
      </Tooltip>

      {ObjectKeys(filter ?? {}).length === 0 &&
        organizationId &&
        !hasAnyGlobalFilter &&
        ObjectKeys(lastUsedFilter?.[organizationId] ?? {}).length > 0 && (
          <Button
            onClick={() => {
              setTableFilter(lastUsedFilter?.[organizationId]);
            }}
            className='rounded-full text-xs'
            variant='secondary'
          >
            Använd senaste filtrering <PlusIcon className='ml-2 size-4' />
          </Button>
        )}
    </>
  );
}
